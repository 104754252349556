import { api } from '@cpms/common/helpers/api-axios'
import { log } from '@cpms/common/helpers/logger'
import { SysRefResponse, SysRefResponseNhs } from '@models/SysRefResponse.interface'
import { SysRefTableName } from '@models/enums/SysRefTableName.enum'
import { sysRefResponseMapper, nhsPayBandSysRefResponseMapper } from '@helpers/misc/sysRef'
import { environmentVariables } from '../environment-variables'

type SysRefReturnType = ReturnType<typeof sysRefResponseMapper>
type SysRefNhsReturnType = ReturnType<typeof nhsPayBandSysRefResponseMapper>
type ObjectType<T> = T extends SysRefTableName.getsysrefnhspayband ? SysRefNhsReturnType : SysRefReturnType

async function getSysRef<T extends SysRefTableName>(table: T): Promise<ObjectType<T>> {
  if (table === SysRefTableName.getsysrefnhspayband || table === SysRefTableName.allleadnetworks || table === SysRefTableName.currentleadnetworks) {
    log.debug('NhsPayBand SysRef request to: ', `${environmentVariables.REACT_APP_SOECAT_API_URL}/${table}`)
    const response = await api.get<Array<SysRefResponseNhs>>(
      `${environmentVariables.REACT_APP_SOECAT_API_URL}/${table}`,
    )
    return nhsPayBandSysRefResponseMapper(response.data) as ObjectType<T>
  }

  log.debug('SysRef request to: ', `${environmentVariables.REACT_APP_SIM_API_URL}/${table}`)
  const response = await api.get<Array<SysRefResponse>>(
    `${environmentVariables.REACT_APP_SIM_API_URL}/sysrefdata/${table}`,
  )
  return sysRefResponseMapper(response.data) as ObjectType<T>
}

export default {
  countries: async () => getSysRef(SysRefTableName.SysRefUkCountry),
  setTypes: async () => getSysRef(SysRefTableName.SysRefSoecatSetType),
  soecatType: async () => getSysRef(SysRefTableName.SysRefSoecatType),
  soecatActivityTypes: async () => getSysRef(SysRefTableName.IctsysRefProtocolActivityType),
  soecatDepartments: async () => getSysRef(SysRefTableName.IctsysRefDepartment),
  soecatDocumentTypes: async () => getSysRef(SysRefTableName.SysRefSoecatDocumentType),
  soecatStatus: async () => getSysRef(SysRefTableName.SysRefSoecatStatus),
  costAttributions: async () => getSysRef(SysRefTableName.SysRefCostAttribution),
  nhsPayBand: async () => getSysRef(SysRefTableName.getsysrefnhspayband),
  allleadnetworks: async () => getSysRef(SysRefTableName.allleadnetworks),
  currentleadnetworks: async () => getSysRef(SysRefTableName.currentleadnetworks),
  excessApplicable: async () => getSysRef(SysRefTableName.excessApplicable),
}
