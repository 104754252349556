import { SysRefResponse, SysRefResponseNhs } from '@models/SysRefResponse.interface'

export const sysRefResponseMapper = (data: Array<SysRefResponse>) =>
  data.map(({ Id, Name, Description }) => ({
    id: Id,
    name: Name,
    description: Description,
  }))

export const nhsPayBandSysRefResponseMapper = (data: Array<SysRefResponseNhs>) =>
  data.map(({ code, cost, id, name }) => ({
    code,
    cost,
    id,
    name,
  }))
