export enum SysRefTableName {
  SysRefUkCountry = 'SysRefUkCountry',
  SysRefSoecatSetType = 'SysRefSoecatSetType',
  SysRefSoecatType = 'SysRefSoecatType',
  IctsysRefProtocolActivityType = 'IctsysRefProtocolActivityType',
  IctsysRefDepartment = 'IctsysRefDepartment',
  SysRefCostAttribution = 'SysRefCostAttribution',
  SysRefSoecatDocumentType = 'SysRefSoecatDocumentType',
  SysRefSoecatStatus = 'SysRefSoecatStatus',
  getsysrefnhspayband = 'getsysrefnhspayband',
  currentleadnetworks = 'getsysrefdata/currentleadnetworks',
  allleadnetworks = 'getsysrefdata/allleadnetworks',
  excessApplicable = 'SysRefExcessApplicable',
}
