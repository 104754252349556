import { AxiosResponse } from 'axios'
import { api } from '@cpms/common/helpers/api-axios'
import { ActivitiesRequest } from '@models/ActivitiesRequest.interface'
import { ActivitiesResponse } from '@models/ActivitiesResponse.interface'
import { environmentVariables } from '@app/environment-variables'
import { log } from '@cpms/common/helpers/logger'

const formatActivitiesResponse = (activities: AxiosResponse<ActivitiesResponse>['data']) => {
  return activities.map(({ id, name, departmentId, activityCode, unitCost, activityBands }) => ({
    id,
    name,
    defaultActivityBands: activityBands || [],
    departmentId,
    activityCode,
    unitCost,
  }))
}

export const getActivities = async (query: ActivitiesRequest) => {
  const endpoint = `${environmentVariables.REACT_APP_SOECAT_API_URL}/getactivities/?activityTypeId=${
    query.activityTypeId
  }&searchText=${query.searchText}${query.departmentId ? `&departmentId=${query.departmentId}` : ''}`
  log.debug(endpoint)
  const response = await api.get<ActivitiesResponse>(endpoint)
  return formatActivitiesResponse(response.data)
}

export const getManualActivities = async (query: ActivitiesRequest & { soecatId: string }) => {
  const endpoint = `${environmentVariables.REACT_APP_SOECAT_API_URL}/getmanualactivities/?soecatId=${
    query.soecatId
  }&activityTypeId=${query.activityTypeId}&searchText=${query.searchText}${
    query.departmentId ? `&departmentId=${query.departmentId}` : ''
  }`
  log.debug(endpoint)
  const response = await api.get<ActivitiesResponse>(endpoint)
  const deDupResponse = response.data.filter((el, idx, res) => res.findIndex((el2) => el2.name === el.name) === idx)
  return formatActivitiesResponse(deDupResponse)
}

export default { getActivities, getManualActivities }
