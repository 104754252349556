import { api } from '@cpms/common/helpers/api-axios'
import { SearchItem } from '../components/SearchBox'
import { environmentVariables } from '../environment-variables'

const fetchFunders = async (filter: string): Promise<SearchItem[]> => {
  const response = await api.get<SearchItem[]>(
    `${environmentVariables.REACT_APP_SIM_API_URL}/getfunders?filter=${filter}`,
  )
  return response.data.map(({ id, name }) => ({ id, name }))
}

export { fetchFunders }
