import { CopySoecatRequest } from '@app/models/CopySoecatRequest.interface'
import { UpdateRequestAuthorisationRequest } from '@app/models/RequestAuthorisationRequest'
import { RequestSoecatAuthorisation } from '@app/models/RequestSoecatAuthorisation'
import { UpdateRequestAuthorisationErrorResponse } from '@app/models/RequestAuthorisationResponse'
import { GetSoecatCommentsRequest, UpdateSoecatCommentsRequest } from '@app/models/SoecatCommentsRequest.interface'
import { GetSoecatCommentsResponse } from '@app/models/SoecatCommentsResponse.interface'
import { GetSoecatStudyRepresentativesResponse } from '@app/models/SoecatStudyRepresentativesResponse.interface'
import { GetSoecatStudysForCopyResponse } from '@app/models/SoecatStudysForCopyResponse.interface'
import { UpdateEtcCommissionerRequest } from '@app/models/statuses/UpdateEtcCommissionerRequest.interface'
import { api } from '@cpms/common/helpers/api-axios'
import { log } from '@cpms/common/helpers/logger'
import { GetStudyInformationResponse } from '@models/StudyInformationResponse.interface'
import { AxiosError } from 'axios'
import { environmentVariables } from '../environment-variables'
import { UpdateProposedETCParams } from '@app/models/UpdateProposedETCParams.interface'
import { UpdateOnHoldStatus } from '@app/models/UpdateOnHoldStatus.interface'
import { SoecatStudyActive } from '@app/models/SoecatStudyActive'

const getSoecatStudyInformation = (id: string | number) => {
  return api
    .get<GetStudyInformationResponse>(`${environmentVariables.REACT_APP_SOECAT_API_URL}/getsoecat/${id.toString()}`)
    .then((response) => {
      return response.data
    })
    .catch((e) => {
      throw e
    })
}

const  GetSoecatStudyIsActive = async (id: string | number) => {
  return api
    .get<SoecatStudyActive>(`${environmentVariables.REACT_APP_SOECAT_API_URL}/GetSoecatStudyActiveStatus/${id.toString()}`)
    .then((response) => {
      return response.data
    })
    .catch((e) => {
      throw e
    })
}

const getSoecatComments = ({ soecatId }: GetSoecatCommentsRequest) => {
  const url = `${environmentVariables.REACT_APP_SOECAT_API_URL}/getsoecatcomments/${soecatId}`
  log.debug('Fetching soecat comments', url)
  return api
    .get<GetSoecatCommentsResponse>(url)
    .then((response) => {
      log.debug('Fetching soecat comments', response.data)
      return response.data
    })
    .catch((e) => {
      log.warn('Error getting soecat comments', e)
      throw e
    })
}

const updateSoecatComments = (payload: UpdateSoecatCommentsRequest) => {
  const url = `${environmentVariables.REACT_APP_SOECAT_API_URL}/putsoecatcomment`
  log.debug('Updating soecat comments', url)
  return api.put(url, payload).catch((e) => {
    log.warn('Failed to update comment')
    throw e
  })
}

const updateSoecatStudyInformation = (
  id: number,
  userId: string,
  shortTitle: string,
  chiefInvestigator?: string,
  drugDevicePartOfResearch?: boolean,
  drugDeviceProvidedFree?: boolean,
) => {
  return api
    .put<null>(`${environmentVariables.REACT_APP_SOECAT_API_URL}/updatesoecat/${id}`, {
      userId,
      shortTitle,
      chiefInvestigator,
      drugDevicePartOfResearch,
      drugDeviceProvidedFree: drugDeviceProvidedFree ?? null,
    })
    .then((response) => response.data)
    .catch((e) => {
      throw e
    })
}

const requestSoecatAuthorisation = ({
  soecatId,
  leadNetworkId,
  additionalInformation,
}: UpdateRequestAuthorisationRequest) => {
  return api
    .put<UpdateRequestAuthorisationRequest>(
      `${environmentVariables.REACT_APP_SOECAT_API_URL}/requestauthorisation/${soecatId}`,
      {
        leadNetworkId,
        additionalInformation,
      },
    )
    .then((response) => response.data)
    .catch((err: AxiosError<UpdateRequestAuthorisationErrorResponse>) => {
      throw err
    })
}

const startSoecatReview = (soecatId: number) => {
  return api
    .put<null>(`${environmentVariables.REACT_APP_SOECAT_API_URL}/putsoecatstartreview/${soecatId}`)
    .then((response) => response.data)
    .catch((e) => {
      throw e
    })
}

const validateSoecat = (payload: RequestSoecatAuthorisation) => {
  return api
    .put<RequestSoecatAuthorisation>(`${environmentVariables.REACT_APP_SOECAT_API_URL}/putvalidatesoecat`, payload)
    .then((response) => response.data)
    .catch((e: AxiosError<UpdateRequestAuthorisationErrorResponse>) => {
      throw e
    })
}

const authoriseSoecat = (payload: RequestSoecatAuthorisation) => {
  return api
    .put<RequestSoecatAuthorisation>(`${environmentVariables.REACT_APP_SOECAT_API_URL}/putsoecatauthorize`, payload)
    .then((response) => response.data)
    .catch((e: AxiosError<UpdateRequestAuthorisationErrorResponse>) => {
      throw e
    })
}

const revertSoecat = (payload: { soecatId: number; userComment: string }) => {
  return api
    .put<null>(`${environmentVariables.REACT_APP_SOECAT_API_URL}/putsoecatrevert`, payload)
    .then((response) => response.data)
    .catch((e) => {
      throw e
    })
}

const updateEtcCommissioner = ({
  soecatId,
  etcCommissionerId,
  treatmentCost,
  comment,
}: UpdateEtcCommissionerRequest) => {
  return api
    .put<null>(`${environmentVariables.REACT_APP_SOECAT_API_URL}/putetccommissioner/${soecatId}`, {
      etcCommissionerId,
      treatmentCost,
      comment,
    })
    .then((response) => response.data)
    .catch((e) => {
      throw e
    })
}

const getSoecatStudyRepresentatives = (soecatId: string | number) => {
  const url = `${environmentVariables.REACT_APP_SOECAT_API_URL}/getsoecatstudyrepresentatives/${soecatId}`
  return api
    .get<GetSoecatStudyRepresentativesResponse>(url)
    .then((response) => {
      return response.data
    })
    .catch((e) => {
      throw e
    })
}

const getSoecatsForCopy = (soecatId: string | number | undefined) => {
  const url = `${environmentVariables.REACT_APP_SOECAT_API_URL}/getsoecatsforcopy/${soecatId}`
  return api
    .get<GetSoecatStudysForCopyResponse>(url)
    .then((response) => {
      return response.data
    })
    .catch((e) => {
      throw e
    })
}

const copySoecat = (payload: CopySoecatRequest) => {
  const endpoint = `${environmentVariables.REACT_APP_SOECAT_API_URL}/${payload.soecatId}/copy`
  log.debug('Copying soecat', endpoint, payload)

  return api
    .post<CopySoecatRequest>(endpoint, payload)
    .then((response) => response.data)
    .catch((e: AxiosError) => {
      throw e
    })
}

const updateProposedETC = async ({ soecatId, proposedETC }: UpdateProposedETCParams): Promise<void> => {
  await api.put<null>(`${environmentVariables.REACT_APP_SOECAT_PROPERTIES_API_URL}/put`, {
    soecatId,
    proposedETC,
  })
}

const updateIsOnHoldStatus = async ({ soecatId, isOnHold, onHoldComment }: UpdateOnHoldStatus): Promise<void> => {
  await api.put<null>(`${environmentVariables.REACT_APP_SOECAT_PROPERTIES_API_URL}/put`, {
    soecatId,
    isOnHold,
    onHoldComment,
  })
}

export {
  getSoecatStudyInformation,
  GetSoecatStudyIsActive,
  updateSoecatStudyInformation,
  requestSoecatAuthorisation,
  startSoecatReview,
  validateSoecat,
  authoriseSoecat,
  revertSoecat,
  updateEtcCommissioner,
  getSoecatComments,
  updateSoecatComments,
  getSoecatStudyRepresentatives,
  getSoecatsForCopy,
  copySoecat,
  updateProposedETC,
  updateIsOnHoldStatus,
}
